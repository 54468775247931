<template>
  <div class="container mt-3">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h3>배정 리스트</h3>
    </div>
    <article>
      <div class="search-box mb-4">
        <b-row class="d-flex mb-4">
          <b-col cols="2">
            <!-- <select class="form-control fl">
              <option value="">회사명</option>
              <option value="메인슬라이드배너">회사명</option>
            </select>            -->
          </b-col>
          <b-col cols="3">
            <!-- <input type="text" class="form-control fl"> -->
          </b-col>
          <b-col cols="4">
            <!-- <b-button variant="primary">검색</b-button> -->
          </b-col>
        </b-row>
        <b-row>
          <table class="table table-hover text-center">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">배정그룹명</th>
                <th scope="col">진단종료기간</th>
                <th scope="col">진단 대상자 수</th>
                <th scope="col">수량</th>
                <th scope="col">진단현황</th>
                <th scope="col">생성일</th>
                <th scope="col">HQ 매니저</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr>
                <td v-if="state.groupList.length == 0" colspan="8">정보가 없습니다.</td>
              </tr>
              <tr v-for="item in state.groupList" :key="item.index">
                <td>{{ item.rowNum }}</td>
                <td class="pointer" @click="clickGroup(item.idx)">{{ item.groupName }}</td>
                <td>{{ item.endDate }}</td>
                <td>{{ item.diagSubjectCnt }}</td>
                <td>{{ item.diagListCnt }}</td>
                <td>{{ item.diagCompleteCnt }} / {{ item.diagListCnt }}</td>
                <td>{{ item.startDate }}</td>
                <td>{{ item.contactManager }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>
    </article>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { reactive } from '@vue/reactivity';
import { useStore } from 'vuex';
import { _axios } from '@/utils/axios';
import router from '../router';
export default {
  name: 'ListSituation',
  setup() {
    const store = useStore();
    const state = reactive({
      groupList: [],
    });

    onMounted(() => {
      getGroupList();
    });

    async function getGroupList() {
      await _axios
        .get(`admin/group/list`, {
        })
        .then(res => {
          const { list } = res.data.data;
          state.groupList = list;
        });
    }
    function clickGroup(idx) {
      router.push({
        name: `Assignment`,
        query: { idx },
      });
    }
    return {
      state,
      clickGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
.ta_info {
  margin-bottom: 50px;
}
table.ta_info tr th {
  width: 110px;
  height: 19px;
  background-color: #8f8f8f;
  border: 1px solid #dee2e6;
  text-align: center;
}
table.ta_info td {
  width: 110px;
  height: 19px;
  text-align: center;
  border: 1px solid #dee2e6;
}
article {
  margin-bottom: 50px;
}
.search-box {
  border-radius: 10px;
  background-color: #fff;
  padding: 25px;
}
.inli {
  display: inline-block;
}

.underline {
  text-decoration: underline;
  color: rgb(64, 64, 253);
}
.btnCl {
  margin: 15px;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
</style>
